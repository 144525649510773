import React, { useMemo } from "react"
import { Container, Box, Heading, Text } from "@chakra-ui/react"
import { useLocation } from "@reach/router"

import type { Props } from "@app/pages/journal"
import type { PageProps } from "@root/types/global"
import { useShopify } from "@app/hooks/useShopify"
import { useConfigContext } from "@app/providers/config"
import ArticleNavigation from "./ArticleNavigation"
import ArticleGrid from "./ArticleGrid"
import ArticleFeaturedCard from "./ArticleFeaturedCard"

const ArticleListByCategory: React.FC<PageProps<Props>> = ({ page, articles }) => {
  const {
    settings: { routes },
  } = useConfigContext()
  const { pathname } = useLocation()
  const { edgeNormaliser } = useShopify()

  const currentCategory = useMemo(
    () => (pathname?.includes(routes.CATEGORY) ? pathname.split("/")?.slice(3)?.[0] : null),
    [pathname, routes]
  )

  const category = page?.categories?.find(category => category?.handle?.current === currentCategory)

  const items = edgeNormaliser(articles)?.filter(article => article?.categories?.some(item => item?.handle?.current === currentCategory))

  const featured = items?.find(({ handle }) => handle?.current === page?.featured?.handle?.current) || items?.[0]

  const truncated = items?.filter(({ handle }) => handle?.current !== featured?.handle?.current)

  return (
    <Container maxW="container.xl" px={[4, 16]}>
      <Box as="section" py={8}>
        <Heading as="h1" textAlign="left" mb={[5, 0]} size="display">
          {page?.title}
        </Heading>
        <ArticleNavigation />
        <Heading size="h2" as="h3" mb={2}>
          {category?.title}
        </Heading>
        <Text size="16" mb={6}>
          {category?.description}
        </Text>
        {featured && <ArticleFeaturedCard article={featured} desktopDivider />}
        <ArticleGrid articles={truncated} />
      </Box>
    </Container>
  )
}

export default React.memo(ArticleListByCategory)
