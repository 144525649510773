import React, { useCallback, useMemo, useState } from "react"
import { Button, Flex, Heading, SimpleGrid, Text, VStack } from "@chakra-ui/react"

import { useConfigContext } from "@app/providers/config"
import ArticleCard from "./ArticleCard"
import { Link } from "gatsby"
import { useRoutes } from "@app/hooks/useRoutes"

type Props = {
  articles: Array<any>
  pageCount?: number
}

const ArticleGrid: React.FC<Props> = ({ articles, pageCount = 8 }) => {
  const {
    settings: { routes },
  } = useConfigContext()
  const { urlResolver } = useRoutes()
  const [pagination, setPagination] = useState(1)

  const paginated = useMemo(() => articles?.slice(0, pagination * pageCount), [articles, pagination, pageCount])

  const handleClick = useCallback(() => setPagination((prevState: number) => prevState + 1), [setPagination])

  return paginated?.length > 0 ? (
    <>
      <SimpleGrid columns={[1, 2]} spacing={"5"}>
        {paginated?.map(article => (
          <ArticleCard key={article?.handle?.current} article={article} />
        ))}
      </SimpleGrid>

      {articles?.length > paginated?.length && (
        <Flex mt={[6, 12, 16]} alignItems="center" justifyContent="center">
          <Button onClick={handleClick} title={"LOAD MORE"} w={["full", "auto"]} variant="outline">
            LOAD MORE
          </Button>
        </Flex>
      )}
    </>
  ) : (
    <Flex mt={[6, 12]} alignItems="center" justifyContent="center" textAlign="center">
      <VStack spacing={4} w={["full", 333]}>
        <Heading size="h5">Nothing to see here</Heading>
        <Text size="14">There aren&#39;t any articles to show, try viewing all articles or items in another category.</Text>
        <Button as={Link} to={urlResolver({ title: "view all" }, routes.ARTICLE).url} size="sm" title={"read all"} w="full">
          Read All
        </Button>
      </VStack>
    </Flex>
  )
}

export default React.memo(ArticleGrid)
